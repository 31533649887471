import React, { useEffect, useRef, useState } from 'react'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { TEXT_STYLES } from 'TEXT_STYLES'
import { flagDataTypes } from 'types/general'

import { showSidebarItem } from 'helpers/sidebar'
import { TYPOGRAPHY } from '@junglescout/styles-library'
import { Icon } from '@junglescout/edna'

const NAV_ITEM_HEIGHT = 40

const ItemWrapper = styled.div`
  width: 100%;
  position: relative;
  height: ${NAV_ITEM_HEIGHT}px;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  flex-shrink: 0;

  ${props =>
    props.isExpanded &&
    css`
      height: ${props.expandedHeight}px;
    `}

  // Active indicator
  &::after {
    content: '';
    width: 4px;
    top: 2px;
    bottom: 2px;
    border-radius: 0 4px 4px 0;
    position: absolute;
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${props => props.theme.colors.grey950};
      &::after {
        background-color: ${props.theme.colors.primarySidenav};
      }
    `}

  ${props =>
    props.isExpanded &&
    !props.isActive &&
    css`
      background-color: ${props => props.theme.colors.grey950};
    `}
`

const activeStatus = props => css`
  color: ${props.theme.colors.white};
  svg:first-of-type {
    color: ${props.theme.colors.primarySidenav};
  }
`

const linkStyles = props => css`
  ${TYPOGRAPHY.headingSm};
  font-weight: 500;
  display: flex;
  width: 100%;
  height: ${NAV_ITEM_HEIGHT}px;
  align-items: center;
  transition: all 0.2s ease;
  color: ${props.theme.colors.grey200};
  cursor: pointer;
  ${props.active &&
    css`
      color: ${props.theme.colors.primarySidenav};
      ${activeStatus(props)}
    `}
  ${!props.active &&
    props.isExpanded &&
    css`
      background-color: ${props => props.theme.colors.grey950};
    `}
  &:hover,
  &.active {
    ${activeStatus(props)};
    background-color: ${props => props.theme.colors.grey950};
  }
  ${props.item && props.item.isActive && activeStatus(props)};
`

const CustomNav = styled.div`
  ${linkStyles}
  width: 100%;
`

const CustomAnchor = styled.a`
  ${linkStyles}
`

const CustomNavLink = styled(NavLink)`
  ${linkStyles}
`

const ChildLink = styled(NavLink)`
  ${TYPOGRAPHY.bodyMd};
  display: flex;
  width: 100%;
  align-items: center;
  transition: color 0.2s ease;
  height: ${NAV_ITEM_HEIGHT}px;
  color: ${props => props.theme.colors.grey200};
  padding-left: 44px;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${props => (props.isExpanded ? 1 : 0)};
  position: relative;
  ${props =>
    props.active &&
    `
    color: ${props.theme.colors.primarySidenav};
    ${activeStatus}
    circle {
      stroke: ${props.theme.colors.grey900};
    }
  `}
  &:hover,
  &.active {
    ${activeStatus}
    circle {
      stroke: ${props => props.theme.colors.grey900};
    }
  }
  &.active {
    color: ${props => props.theme.colors.primarySidenav};
  }
`

export const ItemText = styled.div`
  overflow: hidden;
  user-select: none;
  padding-right: 10px;
  white-space: ${props => (props.enableWrapping ? 'pre' : 'nowrap')};
`

const ChildrenContainer = styled.div`
  ${ChildLink} {
    opacity: ${props => (props.isExpanded ? 1 : 0)};
  }
`

export const NewBadge = styled.span`
  display: inline-block;
  ${TEXT_STYLES.H5Black};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  margin-left: 8px;
  padding: 2px 4px;
`

const NavIcon = ({ iconName }) => {
  return (
    <Icon
      name={iconName}
      color="grey200"
      margin="0 8px 0 16px"
      width="20px"
      height="20px"
    />
  )
}

export const MobileMenuItem = ({
  item,
  expandedOption,
  expandSidebarOption,
  setMenuOpen,
  admin,
  flagData,
  membershipInfo,
  currentPath,
  permissions,
  enableWrapping
}) => {
  const childrenContainerRef = useRef()
  const [expandedHeight, setExpandedHeight] = useState(0)

  useEffect(() => {
    if (childrenContainerRef.current) {
      setExpandedHeight(
        NAV_ITEM_HEIGHT + childrenContainerRef.current.clientHeight || 0
      )
    }
  }, [])

  const handleParentClick = name => expandSidebarOption(name)

  const handleChildrenClick = () => setMenuOpen(false)

  const isCurrentPath = ({ url, isCurrentPath }) => {
    if (isCurrentPath) return isCurrentPath(currentPath)

    return !!currentPath.match(new RegExp(`^${url}(?!/)`))
  }

  const checkActiveStatus = item => {
    if (item.children) {
      return !isEmpty(compact(item.children.filter(checkActiveStatus)))
    }

    return isCurrentPath(item)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderChildren = (item, key) => {
    // NavLink expects a string value or undefined for the active prop
    const active = checkActiveStatus(item) ? item.url : undefined

    if (item.enabled === false) return null

    const { isNew } = item

    return (
      <ChildLink
        end={!item.isCurrentPath}
        to={item.url}
        data-id={item.dataId}
        key={`sub-menu-${key}-${item.name}`}
        onClick={handleChildrenClick}
        active={active}>
        <ItemText enableWrapping={enableWrapping}>
          {item.name}
          {isNew && <NewBadge>{isNew}</NewBadge>}
        </ItemText>
      </ChildLink>
    )
  }

  const renderParentItem = ({ name, iconName, isNew }) => {
    return (
      <>
        <NavIcon iconName={iconName} />
        <ItemText isParent>
          {name}
          {isNew && <NewBadge>{isNew}</NewBadge>}
        </ItemText>
      </>
    )
  }

  const {
    dataId,
    iconName,
    name,
    isNew,
    url,
    separator,
    children,
    target,
    isExternal,
    onClick
  } = item

  const isActive = checkActiveStatus(item)
  const checkExpanded = () => {
    return expandedOption === name
  }
  const isExpanded = checkExpanded()

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }

  if (
    !showSidebarItem({
      item,
      admin,
      flagData,
      membershipInfo,
      permissions
    })
  )
    return null

  if (isExternal) {
    return (
      <ItemWrapper
        isActive={isActive}
        separator={separator}
        onClick={handleOnClick}>
        <CustomAnchor
          href={url}
          target={target}
          data-id={dataId}
          item={{ ...item, isActive }}>
          <NavIcon iconName={iconName} />
          <ItemText>{name}</ItemText>
        </CustomAnchor>
      </ItemWrapper>
    )
  }

  // we do this validation check here instead of in the render method otherwise we can end up with a
  // parent with no children which function as a menu link which expands but should function as a link
  // e.g. the Launch menu item which should show the upgrade page if there are no children
  const filteredChildren = children
    ? children.filter(child =>
        showSidebarItem({
          item: child,
          admin,
          flagData,
          membershipInfo,
          permissions
        })
      )
    : []

  if (filteredChildren.length > 0) {
    return (
      <ItemWrapper
        isActive={isActive}
        separator={separator}
        isExpanded={isExpanded}
        expandedHeight={expandedHeight}
        isParent>
        <CustomNav
          active={isActive}
          data-id={dataId}
          onClick={() => handleParentClick(name, isExpanded)}
          item={{
            ...item,
            isActive,
            isExpanded
          }}>
          {renderParentItem({
            name,
            iconName,
            isExpanded,
            isNew
          })}
        </CustomNav>
        <ChildrenContainer
          ref={childrenContainerRef}
          isExpanded={isExpanded}
          onClick={scrollToTop}>
          {filteredChildren.map((childItem, key) =>
            renderChildren(childItem, key, handleChildrenClick)
          )}
        </ChildrenContainer>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper
      isActive={isActive}
      separator={separator}
      onClick={scrollToTop}>
      <CustomNavLink
        end={!item.isCurrentPath}
        to={url}
        data-id={dataId}
        target={target}
        onClick={() => {
          setMenuOpen(false)
          handleParentClick(null)
        }}
        item={{
          ...item,
          isActive
        }}>
        <NavIcon iconName={iconName} />
        <ItemText>
          {name} {isNew && <NewBadge>{isNew}</NewBadge>}
        </ItemText>
      </CustomNavLink>
    </ItemWrapper>
  )
}

MobileMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  enableWrapping: PropTypes.bool,
  expandedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  admin: PropTypes.bool,
  flagData: flagDataTypes,
  membershipInfo: PropTypes.object,
  expandSidebarOption: PropTypes.func,
  currentPath: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  setMenuOpen: PropTypes.func.isRequired,
  isNew: PropTypes.string
}

MobileMenuItem.defaultProps = {
  flagData: {},
  isNew: undefined,
  enableWrapping: false,
  admin: false,
  expandedOption: null,
  membershipInfo: undefined,
  expandSidebarOption: undefined,
  permissions: {}
}
